:root {
  --ion-color-primary: #5c0fd9;
  --ion-color-primary-contrast: #fff;
  --ion-color-primary-secoundary: #6426c9;
  --ion-text-white: #fff;
  --text-theme: #fff;
  --text-common-dark: #23262f;

  /* Text colors */
  /* --primary-text-color: #fff;
  --secoundary-text-color: #fff;
  --light-theme-text: #000; */

  /* Gradient styles */
  --profit-bg: #eaf5ee;
  --loss-bg: #fbefef;
  --gradient-color-primary: var(--ion-color-primary);
  --gradient-color-secondary: var(--ion-color-primary);
  --font-family: 'Jost', sans-serif;

  /* Home page */
  --home-page-img-bg-color: #231f20;
  --header-profile-bg-color: #2a3246;

  /** secondary **/
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-success: #006a1d;
  --ion-color-danger: var(--ion-color-primary);
  --ion-color-lost: var(--ion-color-primary);
  --ion-color-light: #f4f5f8;

  /** Sports List Navbar Colors **/
  --ion-nav-card-text: #fff;
  --ion-header-link-color: rgba(255, 255, 255, 0.6);

  /** Back and Lay **/
  --back-odd-background: linear-gradient(180deg, #9fe8ff 0%, #1a93c7 100%);
  --lay-odd-background: linear-gradient(180deg, #ed826a 0%, #ca634d 100%);
  --premium-odd-background: #72e3a0;
  --ion-toast--success: #69bb7b;

  /** Table colour **/
  --table-headers-light: rgba(32, 32, 32, 0.6);
  --odd-btn-animation-back-color: #c9e7ff;
  --odd-btn-animation-lay-color: #d9afb8;
  --nav-list-live-icon-color: var(--ion-color-primary);

  /** Header Logo Sizes **/
  --logo-xl-width: 136px;
  --logo-xl-height: 90px;
  --logo-lg-width: 218px;
  --login-input-bg: #151313;
}

.ios body,
.md body {
  --modal-bg-img: url('../assets/images/common/modal1.png');
  --modal-bg-img2: url('../assets/images/common/modal11.png');

  --modal-bg-img1: #231f20;
  --row-bg: #231f20;
  --profit-bg: #2f3a33;
  --loss-bg: #433737;
  --ion-background: #231f20;
  --ion-header-background: linear-gradient(
    180deg,
    var(--ion-color-primary) 0%,
    var(--ion-color-primary-secoundary) 100%
  );
  --btn-bg: linear-gradient(
    180deg,
    var(--ion-color-primary) 0%,
    var(--ion-color-primary-secoundary) 100%
  );
  --btn-bg-login: #fff;
  --btn-bg-login-color: #231f20;
  --btn-bg1: linear-gradient(180deg, #444444 0%, #111111 100%);
  --sub-header-color: linear-gradient(180deg, #444444 0%, #111111 100%);
  --active-tab: var(--ion-color-primary);
  --tab-text: #fff;
  --tab-text1: #fff;
  --text1: #c0c0c0;
  --text2: #797c86;
  --border-bottom: #712be3;
  --bg1: linear-gradient(180deg, #444444 0%, #111111 100%);
  --sub-header-text-color: #fff;
  --table-head-bg: linear-gradient(180deg, #444444 0%, #111111 100%);
  --table-head-text: #fff;
  --table-body-background: #231f20;
  --disabled-bg: #3a3c40;
  --footer-text: #fff;
  --footer-bg: linear-gradient(180deg, #444444 0%, #111111 100%);
  --ion-background-color-rgb: 0, 20, 61;
  --ion-background-color: #231f20;

  --ion-background-secondary: #231f20;
  --ion-background-tertiary: #30333d;

  --scorcard-color: #fff;
  --ion-text-color: #fff;
  --ion-text-light: #ffffff;
  --ion-text-dark: #0a0d15;
  --ion-text-dark-rgb: 10, 13, 21;

  /** Card Variables**/
  --ion-card-background: linear-gradient(180deg, #231f20 0%, #1a1c22 100%);
  --ion-card-text-color: #00143d;
  --ion-card-background-contrast: linear-gradient(
    180deg,
    #231f20 0%,
    #1a1c22 100%
  );
  --ion-card-border: 1px solid rgba(10, 13, 21, 0.1);
  --ion-card-border-color-rgb: 10, 13, 21;

  /** Toolbar Variables**/
  --ion-toolbar-background: none;
  --ion-item-background: none;
  --ion-item-background-activated: none;

  /** Popover Variables**/
  --ion-popover-background: var(--ion-background);
  --ion-popover--hover-background: #231f20;

  /** Expansion Panel Variables**/
  --accordion-summary-root-background: #ffffff;
  --accordion-details-root-background: #231f20;

  /** All Markets variables **/
  --am-accordion-details-root-background: #ffffff;

  /** IonSelect Styles **/
  --select-element-background: var(--ion-popover--hover-background);
  --select-popover-background: var(--ion-popover-background);
  --select-popover-active-background: var(--ion-color-primary);

  /** Dialog Styles **/
  --dialog-header-background: #202d57;
  --dialog-content-background: var(--table-head-bg);
  --input-text-color: #ffffff;

  /* Odds info icons */
  --market-info-icons: var(--ion-color-primary);

  /* Scorecard */
  --score-card-details-color: #fff;
  --accordian-headers-color: #fff;

  /* login */
  --login-margin-btn:5px;
  --login-img-height:60px;
  --login-card-height:500px;

  --market-table-row: var(--ion-background);
  --text-primary: #fff;
  --border-color: #712be3;
  --table-border-color: #712be3;
  --game-category-icons: brightness(0) invert(1);
  --game-category-icons1: brightness(0) invert(1);
  --side-menu: linear-gradient(180deg, #474747 -92.03%, #121111 130.04%);
  --selected-border: var(--ion-color-primary-secoundary);
  --suspended-bg: rgba(255, 255, 255, 0.8);
  --suspended-text: #000;
  --date-icon: invert(0);
  --font-weight: 400;
  --label-color: #fff;
  --filter-border: #712be3;
  --report-table-header: linear-gradient(180deg, #474747 0%, #121111 100%),
    linear-gradient(0deg, #712be3, #712be3);
  --report-table-border-color: #712be3;
  --button-bg1: #231f20;
  --modal-bg: #231f20;
  --scorecard-bg: #231f20;
  --scorecard-text: #fff;
  --card-bg: #2f2e2e;
  --active1: #2f2e2e;
  --profile-card: linear-gradient(180deg, #474747 -50%, #121111 130.04%),
    linear-gradient(0deg, #712be3, #712be3);
  --profile-tab: #2f2e2e;
}

.ios body.yellow,
.md body.yellow {
  --profile-card: #fff;
  --profile-tab: #e9e9e9;
  --modal-bg-img: url('../assets/images/common/modal2.png');
  --modal-bg-img2: url('../assets/images/common/modal22.png');
  --modal-bg-img1: #fff;
  --card-bg: #edecec;
  --scorecard-text: #000;
  --scorecard-bg: #fff;
  --row-bg: #fbefef;
  --profit-bg: #eaf5ee;
  --loss-bg: #fbefef;
  --filter-border: rgba(53, 48, 49, 0.4);
  --label-color: #231f20;
  --ion-background: #ffffff;
  --font-family: 'Jost', sans-serif;
  --btn-bg: #ffffff;
  --btn-bg-login: #fff;
  --btn-bg-login-color: #5c0fd9;
  --btn-bg1: #231f20;
  --tab-text: #fff;
  --tab-text1: #5c0fd9;
  --text1: #86777b;
  --text2: #797c86;
  --active-tab: #5c0fd9;
  --border-bottom: #5c0fd9;
  --ion-text-color: #231f20;
  --bg1: linear-gradient(180deg, #231f20 0%, #1a1c22 100%);
  --sub-header-color: #231f20;
  --sub-header-text-color: #fff;
  --disabled-bg: #353031;
  --ion-color-primary-shade: #231f20;
  --ion-color-primary: #5c0fd9;
  --ion-header-background: #5c0fd9;
  --ion-text-light: #000;
  --ion-color-primary-shade: var(--ion-color-primary);
  --ion-color-primary-contrast: #000;
  --home-page-img-bg-color: #fff;
  --header-profile-bg-color: var(--ion-header-background);
  --ion-card-background: var(--ion-header-background);
  --ion-background-color: #ffffff;
  --ion-color-light: #000;
  --ion-nav-card-text: #000;
  --table-body-background: #fff;
  --ion-color-light-contrast: var(--ion-header-background);
  --ion-background-secondary: var(--ion-header-background);
  --accordion-details-root-background: #fff;
  --input-text-color: #fff;
  --ion-text-light-secondary: #000;
  --back-odd-background: linear-gradient(180deg, #9fe8ff 0%, #1a93c7 100%);
  --lay-odd-background: linear-gradient(180deg, #ed826a 0%, #ca634d 100%);
  --ion-text-dark: var(--ion-color-primary);
  --ion-toast--success: #69bb7b;
  --ion-color-primary-tint: var(--ion-color-primary);
  --ion-card-background-contrast: #fff;
  --accordian-headers-color: #000;
  --scorcard-color: #000;
  --text-theme: #000;
  --footer-text: #fff;
  --footer-bg: #231f20;
  --sidebar: #fff;
  --table-head-bg: #5c0fd9 !important;
  --table-head-text: #fff;
  --market-table-row: #fff;
  --text-primary: #61585a;
  --border-color: #712be3;
  --table-border-color: #fff;
  --side-menu: linear-gradient(180deg, #474747 -92.03%, #121111 130.04%);
  --selected-border: #712be3;
  --suspended-bg: rgba(35, 31, 32, 0.9);
  --suspended-text: #fff;
  --date-icon: invert(1);
  --font-weight: 700;
  --report-table-header: #231f20;
  --report-table-border-color: #231f20;
  --button-bg1: #e2e2e2;
  --modal-bg: #231f20;
  --active1: #2f2e2e;
}
